import { useEffect } from 'react';
import { entityStore, STATUS } from './entity-store.js';
import { useSirenEntityUpdates } from './use-siren-entity-updates';
import { Entity } from 'siren-parser';

/**
 * A React hook to use a Siren entity specified by href.  If the entity in the entity store changes, this hook keeps
 * the entity up to date.
 * @param {{href: string}} href The href of the entity to use.
 * @return {{refreshing: boolean, href: string, loading: boolean, entity}}
 */
export default function useSirenEntity<E extends Entity>({ href }: { href?: string }): {
  href?: string,
  refreshing: boolean,
  loading: boolean,
  entity?: E
} {
  useEffect(function loadIfNeeded() {
    function load() {
      if (href) {
        entityStore.getAndFetch(href);
      }
    }
    load();
    return entityStore.addEntityListener(href, () => {
      const { status } = entityStore.get(href);
      if ([STATUS.NOT_STORED, STATUS.EXPIRED].includes(status)) {
        load();
      }
    });
  }, [href]);

  return useSirenEntityUpdates<E>({ href });
}
