import SirenParse from 'siren-parser';

import { entityStore } from './entity-store.js';

export const getActionFields = function (action, { includeDefaults = true } = {}) {
  if (!action.fields) {
    return;
  }
  let fields;
  if (['GET', 'HEAD'].includes(action.method.toUpperCase())) {
    fields = new URL(action.href, window.location.origin).searchParams;
  } else if (action.type === 'application/x-www-form-urlencoded') {
    fields = new URLSearchParams();
  } else {
    fields = new FormData();
  }

  if (!includeDefaults) {
    return fields;
  }

  // if the field is specified multiple times, assume it is intentional
  for (const { name, value, files, type, checked } of action.fields) {
    if (files) {
      for (const file of files) {
        fields.append(name, file, file.name);
      }
    } else if (type === 'radio') {
      if (checked && value !== undefined) {
        // lone radio button doesn't really make sense, but it better conforms to the siren spec
        // so support it
        fields.append(name, value);
      } else if (Array.isArray(value)) {
        value.forEach(radio => {
          if (radio.checked && radio.value !== undefined) {
            fields.append(name, radio.value);
          }
        });
      }
    } else if (type === 'checkbox') {
      if (checked) {
        fields.append(name, value);
      }
    } else {
      fields.append(name, value === undefined ? '' : value);
    }
  }
  return fields;
};

/**
 * @param action
 * @param {Object | URL | URLSearchParams | FormData} fields   If argument is an object, each field will be mapped
 * to data fields (query field, form data).
 * @param {Boolean} appendArrays false to join arrays with commas, true to send the query param multiple times (default = false)
 * @param {Boolean} includeDefaults true to include default values from the action (default = true)
 * @return {{method: string, body: string, url: URL}}
 */
export const parseAction = function (action, fields, {
  appendArrays = false,
  includeDefaults = true,
} = {}) {
  if (!action) {
    throw new Error('No action given');
  }
  fields = fields || {};
  if (fields.constructor.name === 'Object') {
    const fieldsFromObject = getActionFields(action, { includeDefaults });
    for (const property of Object.getOwnPropertyNames(fields)) {
      const fieldValue = fields[property];
      // matches all but null or undefined
      if (fieldValue != null) {
        if (appendArrays && Array.isArray(fieldValue)) {
          if (fieldValue.length) {
            fieldsFromObject.delete(property);
            fieldValue.forEach(value => fieldsFromObject.append(property, value));
          }
        } else {
          fieldsFromObject.set(property, fieldValue);
        }
      }
    }
    fields = fieldsFromObject;
  }
  const method = action.method.toUpperCase();
  const url = new URL(action.href, window.location.origin);
  let body;

  if (fields) {
    if (['GET', 'HEAD'].includes(method)) {
      url.search = fields;
    } else if (action.type.indexOf('json') !== -1) {
      const json = {};
      fields.forEach((value, key) => (json[key] = value));
      body = JSON.stringify(json);
    } else {
      body = fields;
    }
  }
  return { method, url, body };
};

function isS3Url(url) {
  const { host } = url;
  // S3 urls can be in of these two forms: https://s3.amazonaws.com/<bucket-name> or https://<bucket-name>.s3.<region>.amazonaws.com
  return host === 's3.amazonaws.com' || /\.s3\..*\.amazonaws\.com$/.test(host);
}

export const submitAction = async function (action, fields, opts) {
  const { method, url, body } = parseAction(action, fields, opts);
  if (isS3Url(url)) {
    // eslint-disable-next-line no-use-before-define
    return _submitS3Action({ url, method, body });
  }

  const token = await entityStore.getToken();
  if (method === 'GET') {
    const { entity } = await entityStore.fetchWait(url.href, opts?.bypassCache ?? true);
    return { entity: SirenParse(entity) };
  }
  const headers = new Headers();
  token && headers.append('Authorization', `Bearer ${token}`);
  action.type &&
    action.type !== 'multipart/form-data' &&
    headers.set('Content-Type', action.type);
  const response = await fetch(url.href, { method, body, headers });
  const entity = await entityStore.parseJsonResponse(response);
  // update the entity store with the returned entity
  if (entity) {
    const self =
      entity.links && entity.links.find((l) => l.rel.includes('self'));
    self && entityStore.update(self.href, entity);
  }
  return { entity: SirenParse(entity), response };
};

async function _submitS3Action({ url, method, body }) {
  const response = await fetch(url.href, { method, body });
  if (!response.ok) {
    const responseXml = await response.text();
    const responseDocument = new DOMParser().parseFromString(
      responseXml,
      'text/xml',
    ).documentElement;
    const errorMessage = responseDocument.querySelector('Message').innerHTML;
    throw new Error(errorMessage);
  }
  return { response };
}
