import { css } from '~/lib/bn-lit-element';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { colors } from './colours.js';

export const fonts = css`
  @import url("https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700");

  :root,
  * {
    --heading-font: "Nunito Sans", sans-serif;
    --content-font: "Nunito Sans", sans-serif;

    --primary-font-color: #777777;
    --primary-background-color: #f9fafb;
    --secondary-background-color: #e4e7e7;
    --primary-link-color: colors.teal[60];
  }
`;
