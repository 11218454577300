import { store } from './store.js';
import ReactDOM from 'react-dom';

export class EntityResponseError extends Error {
  constructor(message, code) {
    super(message);
    this.code = code;
  }
}

export const STATUS = {
  FETCHING: 'fetching',
  EXPIRED: 'expired',
  STALE_REFRESH: 'stale-refresh',
  NOT_STORED: 'not-stored',
  FRESH: '',
  ERROR: 'error',
};

class EntityStore {
  constructor() {
    this._inflight = 0;
    this._listeners = new Map();
    this._entityListeners = new Map();
    this._store = new Map();
    const initialState = store.getState();
    this._token = initialState.token;
    this._subject = initialState.currentSubject;

    this._initialTokenPromise =
      this._token !== undefined
        ? Promise.resolve(this._token)
        : new Promise((resolve) => {
          this._resolveInitialToken = resolve;
        });

    store.subscribe(() => this._stateChanged(store.getState()));
  }

  /**
   * Gets the current set token
   */
  async getToken() {
    // block all API calls on the async load of the token from storage, or silent sign-in if need be
    await this._initialTokenPromise;
    return this._token;
  }

  _setToken(token) {
    // undefined means it doesn't exist in the store yet, null means unauthenticated
    if (this._token !== token) {
      this._token = token;
    }
  }

  _setSubject(subject) {
    if (this._subject === subject) {
      return;
    }

    this._subject = subject;
    if (!subject) {
      this._store.clear();
    }

    // After the potential clear, notify all listeners
    for (const [href] of this._entityListeners) {
      const { entity, error, status } = this._store.get(href) || {};
      this._notify({ href, entity, error, status });
    }
  }

  /**
   * Removes all expired items
   */
  clearExpiredItems() {
    const currentTime = new Date().getTime();
    for (const [href, item] of this._store) {
      if (item.expiresAt < currentTime) {
        this._store.set(href, { ...item, status: STATUS.EXPIRED });
      }
    }
  }

  /**
   * @typedef Entity Object
   */
  /**
   * Add event listener for a specific entity
   * @param href : unique identifier for the entity (like the HREF)
   * @param { function({ status: string, entity: Entity }) } callback : called with the updated entity when changed
   */
  addEntityListener(href, callback) {
    if (!href || typeof callback !== 'function') {
      return () => {}; // noop function
    }

    this._getEntityListeners(href).add(callback);

    return () => {
      this.removeEntityListener(href, callback);
    };
  }

  /**
   * Remove event listener for a specific entity
   * @param href : unique identifier for the entity (like the HREF)
   * @param { function({}) } callback : the function instance to remove (called with addEntityListener)
   */
  removeEntityListener(href, callback) {
    if (!href || typeof callback !== 'function') {
      return;
    }

    this._getEntityListeners(href).delete(callback);
  }

  /**
   * Generic event listener
   * @param event : [inflight, unauthorized]
   */
  addListener(event, callback) {
    let callbacks = this._listeners.get(event);
    if (!callbacks) {
      this._listeners.set(event, (callbacks = new Set()));
    }
    callbacks.add(callback);
  }

  /**
   * Generic event listener
   * @param event : [inflight, unauthorized]
   */
  removeListener(event, callback) {
    const callbacks = this._listeners.get(event);
    if (!callbacks) {
      return;
    }
    callbacks.delete(callback);
  }

  /**
   * Return an item & begin a fetch for the same entity
   */
  getAndFetch(href, bypassCache) {
    // Don't have the item
    if (!this._store.has(href) || bypassCache) {
      this._fetchItem(href, bypassCache);
      const item = { status: STATUS.FETCHING, entity: null };
      this._store.set(href, item);
      return item;
    }

    const item = this._store.get(href);
    if (item.status === STATUS.EXPIRED) {
      // Respond with stale & update the item
      this._fetchItem(href, bypassCache);
      item.status = STATUS.STALE_REFRESH;
      this._store.set(href, item);
      return item;
    }

    return item;
  }

  get(href) {
    return this._store.get(href) || {
      status: STATUS.NOT_STORED,
      entity: null,
    };
  }

  async parseJsonResponse(response) {
    const contentType = response.headers.get('content-type');
    if (
      !contentType ||
      contentType.search(/application\/(vnd.siren\+)?json/) === -1
    ) {
      return;
    }
    const json = await response.json();
    if ([200, 201, 203, 205, 206].includes(response.status)) {
      return json;
    }
    if (json && json.error) {
      throw new EntityResponseError(json.error, response.status);
    }
  }

  async _fetchItem(href, bypassCache) {
    try {
      const headers = new Headers();
      if (bypassCache) {
        headers.append('pragma', 'no-cache');
        headers.append('cache-control', 'no-cache');
      }
      const token = await this.getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      this._emit('inflight', { count: ++this._inflight });
      const response = await fetch(href, { method: 'GET', headers });
      if (response.status === 401) {
        this._emit('unauthorized', { token });
      }
      const entity = await this.parseJsonResponse(response);
      const maxAge = this._getMaxAge(response.headers);
      return this.update(href, entity, maxAge);
    } catch (err) {
      return this._setError(href, err);
    } finally {
      this._emit('inflight', { count: --this._inflight });
    }
  }

  /**
   * Expire a range of hrefs
   * @param { function({ href }) } callback: returns true if the href should be expired
   */
  expireBy(callback) {
    // useSirenEntity will see stale entity, but loading will be true while refresh is happening. 'Flickering' make occur if dependant on loading, not just entity defined
    for (const [href, entity] of this._store) {
      if (callback({ href })) {
        this._store.set(href, { ...entity, status: STATUS.EXPIRED });
        this._notify({ href, entity, status: STATUS.EXPIRED });
      }
    }
  }

  /**
   * invalidate a range of hrefs
   * @param { function({ href }) } callback: returns true if the href should be invalidated
   */
  invalidateBy(callback) {
    const invalidatedHrefs = [];
    for (const [href] of this._store) {
      if (callback({ href })) {
        invalidatedHrefs.push(href);
      }
    }
    // Listener may work on the store as we iterate it, make a copy of affected hrefs first
    invalidatedHrefs.forEach((href) => {
      this._store.delete(href);
      this._notify({ href });
    });
  }

  invalidateHref(href) {
    return this.getAndFetch(href, true);
  }

  /**
   * Invalidate an href in the cache and fetch a new entity
   * @param {*} href
   * @return {Promise<{ status: STATUS.FRESH, entity: Entity }>} the newly fetched entity
   */
  invalidateAndWait(href) {
    return this.fetchWait(href, true);
  }

  /**
   * Fetch an entity, wait until the entity is available before returning.
   * @param {*} href
   * @param {boolean} bypassCache
   */
  async fetchWait(href, bypassCache = true) {
    const result = this.getAndFetch(href, bypassCache);
    if (result.status !== STATUS.FETCHING) {
      return result;
    }
    return new Promise((resolve, reject) => {
      const listener = function ({ entity, error, status }) {
        if (error) {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({ error });
          this.removeEntityListener(href, listener);
        } else if (status !== STATUS.FETCHING) {
          resolve({ status: STATUS.FRESH, entity });
          this.removeEntityListener(href, listener);
        }
      }.bind(this);

      this.addEntityListener(href, listener);
    });
  }

  async update(href, entity, maxAge) {
    const expiresAt = maxAge + new Date().getTime();

    const status = STATUS.FRESH;
    const storeItem = { status, entity, expiresAt };
    this._store.set(href, storeItem);

    // if there are embedded sub-entities, prime that cache...
    if (entity && entity.entities) {
      for (const sub of entity.entities) {
        if (!sub.links) {
          continue;
        }
        const self = sub.links.find((link) => link.rel.includes('self'));
        if (!self) {
          continue;
        }
        const copy = JSON.parse(JSON.stringify(sub));
        copy.rel = undefined;

        const itemExpires = this._getSubEntityMaxAge(self.href);
        this._store.set(self.href, {
          status,
          entity: copy,
          expiresAt: itemExpires,
        });
        this._notify({ href: self.href, entity: copy, status });
      }
    }
    this._notify({ href, entity, status });
    return storeItem;
  }

  async _setError(href, error) {
    const status = STATUS.ERROR;
    const storeItem = { status, entity: null, error, expiresAt: 0 };
    this._store.set(href, storeItem);
    this._notify({ href, entity: null, error, status });
    return error;
  }

  _getEntityListeners(href) {
    if (!this._entityListeners) {
      this._entityListeners = new Map();
    }
    let listeners = this._entityListeners.get(href);
    if (!listeners) {
      this._entityListeners.set(href, (listeners = new Set()));
    }
    return listeners;
  }

  _getMaxAge(headers) {
    let maxAge = 0;
    const cacheControl = headers.get('cache-control');

    if (cacheControl) {
      const reg = /max-age=([0-9]*)/g;
      const groups = reg.exec(cacheControl);
      if (groups && groups.length > 0) {
        maxAge = parseInt(groups[1]) * 1000;
      }
    }

    return maxAge;
  }

  _getSubEntityMaxAge(href) {
    const item = this._store.get(href);

    // Default max-age is 5s for sub-entities
    const subEntityDefaultExpiresAt = 5000 + new Date().getTime();
    if (!item || !item.expiresAt) {
      return subEntityDefaultExpiresAt;
    }

    if (item.expiresAt < subEntityDefaultExpiresAt) {
      return subEntityDefaultExpiresAt;
    }

    return item.expiresAt;
  }

  async _emit(event, data) {
    const callbacks = this._listeners.get(event);
    if (!callbacks) {
      return;
    }
    callbacks.forEach((fn) => fn(data));
  }

  _notify({ href, entity, error, status }) {
    const listeners = this._getEntityListeners(href);

    // for now, we batch notifications for a single href. In the future, we could expand this to notifications for
    // embedded entities coming from the same update, and even further we could choose to batch async updates that
    // come within a small time window, to further alleviate re-renders.
    ReactDOM.unstable_batchedUpdates(() => {
      listeners.forEach((fn) => {
        try {
          fn({ entity, error, status });
        } catch (err) {
          /* callback failed */
        }
      });
    });
  }

  _stateChanged({ token, currentSubject }) {
    this._setSubject(currentSubject);
    this._setToken(token);

    if (this._resolveInitialToken && token !== undefined) {
      this._resolveInitialToken(token);
      delete this._resolveInitialToken;
    }
  }
}

export const entityStore = new EntityStore();

export default entityStore;
