import { useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import useStoreValues from '../use-store-values.js';
import { EntityResponseError } from '../entity-store.js';

/**
 * Initializes external services [Sentry, PostHog]
 * Listens for user/organizations/path changes to the store & calls external services
 */
export function useSentry(config) {
  const { currentUserHref, currentOrgHref } = useStoreValues(['currentUserHref', 'currentOrgHref']);

  // Initialize sentry on startup
  useEffect(() => {
    _initializeSentry(config);
  }, [config]);

  // Re-configure sentry scope when the current user or organization has changed
  useEffect(() => {
    Sentry.configureScope((scope) => {
      scope.setUser({ userId: currentUserHref, currentOrganizationId: currentOrgHref });
    });
  }, [currentUserHref, currentOrgHref]);
}

const _initializeSentry = (config) => {
  const sentryConfig = config && config.sentry;
  if (!sentryConfig) {
    return;
  }

  const expectedProductionHostName = 'ui.binsentry.com';
  if (sentryConfig.environment === 'production' && window.location.hostname !== expectedProductionHostName) {
    // This is to stop any devs from inadvertently poisoning our production issues/performance data when developing locally
    console.warn(`Sentry.io not initialized. Hostname does not match '${expectedProductionHostName}'`);
    return;
  }

  Sentry.init({
    dsn: sentryConfig.dsn,
    environment: sentryConfig.environment,
    integrations: [Sentry.browserTracingIntegration({
      enableInp: true,
    })],
    tracesSampleRate: 0.05,
    tracePropagationTargets: ['https://ui.local.dev.binsentry.test', 'https://ui.staging.dev.binsentry.com', 'https://ui.binsentry.com'],
    beforeSend: function (event, hint) {
      const { originalException } = hint;
      if (originalException instanceof EntityResponseError) {
        if ([401, 403, 404, 422].includes(originalException.code)) {
          // Returning null drops the error entirely
          return null;
        }
      }

      return event;
    },
  });
};
