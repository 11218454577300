import useSirenEntity from './use-siren-entity.ts';
import { getAccountHref } from './organization-helper.js';

export default function useAccount({ organizationId }) {
  const {
    entity: organizationEntity,
    loading: organizationLoading,
  } = useSirenEntity({ href: organizationId });

  const accountHref = getAccountHref(organizationEntity);

  const { entity: accountEntity, loading: accountLoading } = useSirenEntity({
    href: accountHref,
  });

  return {
    account: accountEntity,
    loading: accountLoading || organizationLoading,
  };
}
