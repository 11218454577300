import { useEffect } from 'react';
import { store } from '../store.js';
import useStoreValues from '../use-store-values.js';
import useSirenEntity from '../use-siren-entity.ts';
import * as storeActions from '../store-actions.js';

/**
 * Loads the current organization and updates the store.organizationSettings & store.organizationType values
 */
export function useCurrentOrganizationWatcher() {
  const { currentOrgHref, token } = useStoreValues(['currentOrgHref', 'token']);
  const { entity } = useSirenEntity({ href: token && currentOrgHref });

  useEffect(() => {
    store.dispatch(storeActions.setOrganizationSettings(entity?.properties.settings));
    const organizationType = entity?.getSubEntityByRel('https://api.binsentry.com/rel/organization-type').properties.name;
    store.dispatch(storeActions.setOrganizationType(organizationType));
  }, [entity]);
}
