import { useCallback, useMemo } from 'react';
import { entityStore } from './entity-store.js';
import SirenParse, { Entity } from 'siren-parser';
import { useSyncExternalStore } from 'use-sync-external-store/shim/index.js';
import _ from 'lodash';

/**
 * A React hook to subscribe to entitystore updates for a given href. This hook does NOT trigger a load of the entity!
 * @param {string} href The href of the entity to use.
 * @return {{refreshing: boolean, href: string, loading: boolean, entity}}
 */
export function useSirenEntityUpdates<E extends Entity>({ href }: { href?: string }) : {
  href?: string,
  refreshing: boolean,
  loading: boolean,
  entity?: E
} {
  const subscribe = useCallback((cb) => entityStore.addEntityListener(href, cb), [href]);
  const getSnapshot = useMemo(() => createGetSnapshot(href), [href]);
  const { loading, refreshing, rawEntity } = useSyncExternalStore(subscribe, getSnapshot);
  const entity = useMemo(() => rawEntity ? SirenParse(rawEntity) as E : undefined, [rawEntity]);
  return { href, loading, refreshing, entity };
}

function createGetSnapshot(href?: string) {
  let snapshot: { refreshing: boolean, loading: boolean, rawEntity: object | undefined };
  return () => {
    const newSnapshot = getUnstableSnapshot(href);
    snapshot = _.isEqual(snapshot, newSnapshot) ? snapshot : newSnapshot;
    return snapshot;
  };
}

function getUnstableSnapshot(href?: string) {
  if (!href) {
    return { refreshing: false, loading: false, rawEntity: undefined };
  }
  const { status, entity: rawEntity }: { status: string, entity?: object } = entityStore.get(href);
  const loading = ['stale-refresh', 'fetching', 'not-stored'].includes(status);
  const refreshing = ['stale-refresh'].includes(status);
  return { href, loading, refreshing, rawEntity };
}
